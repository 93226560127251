<template>
  <main>
      <headerElem></headerElem>
    <div class="mentions" v-html="contact" />
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: "contact",
      components: {
    HeaderElem,
  },
  data() {
    return {
      contact: window.contact,
    };
  },
};
</script>
